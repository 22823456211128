import { ReactComponent as Plus } from '@/assets/icons/Plus.svg'
import BasicModal from '@/common/components/Modal/BasicModal'
import StatisticContainer from '@/common/components/StatisticContainer/StatisticContainer'
import { StyledButton } from '@/common/components/StyledButton/StyledButton'
import { ProjectChainData } from '@/data/ProjectChainData'
import { UserAlert } from '@/proto/api_pb'
import { userService } from '@/service/UserService'
import { colors } from '@/styles/colorScheme'
import { Box, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import AlertsViewFooter from '../components/AlertsViewFooter/AlertsViewFooter'
import { DisplayAlertV1 } from '../components/DisplayAlertV1'
import useAlertView from '../hooks/useAlertView'

export const AlertsV1View = () => {
  const {
    deleteAlert,
    openModal,
    value,
    alertId,
    closeModal,
    getAlerts,
    updateAlertStatus,
    userAlerts,
    userDestinations,
  } = useAlertView()
  const [totalAlerts, setTotalAlerts] = useState<number>(0)
  const [totalTodayAlerts, setTotalTodayAlerts] = useState<number>(0)
  const [avgResponse, setAvgResponse] = useState<number>(0)
  const router = useRouter()

  //get if it's mobile or not
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsMobile(true)
    }
  }, [])

  // const getChains = async () => {
  //   return await userService.getChain()
  // }

  // useEffect(() => {
  //   const response = getChains()
  //   console.log('chain', response.then(res => console.log(res)))
  // },[])

  const totalAlertsData = useCallback(async () => {
    const result = await userService.getStatisticsByUser()
    setTotalTodayAlerts(result?.data?.totalAlertsToday)
    setTotalAlerts(result?.data?.totalAlerts)
    setAvgResponse(Math.round(result?.data?.avgResponseTime * 100) / 10)
  }, [])

  useEffect(() => {
    totalAlertsData()
  }, [totalAlertsData, router])

  useEffect(() => {
    getAlerts()
  }, [getAlerts, value])

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        gap: '32px',
        '@media (max-width: 600px)': {
          gap: '20px',
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: ProjectChainData.CHAIN_FONT_TITLE,
          fontSize: ProjectChainData.TITLE_SIZE,
          color: colors.white.primary,
          lineHeight: 1.2,
          textTransform: ProjectChainData.IS_TITLE_UPPERCASE ? 'uppercase' : 'none',
          '@media (max-width: 600px)': {
            fontSize: '32px',
          },
        }}
      >
        Your{' '}
        <span style={{ color: ProjectChainData.CHAIN_PRIMARY_COLOR }}>
          {ProjectChainData.CHAIN_NAME}
        </span>{' '}
        alerts
      </Typography>
      {isMobile && (
        <Box>
          <StyledButton
            className={isMobile ? 'isMobileFull' : ''}
            onClick={() => router.push('/create')}
          >
            {' '}
            <Plus />
            New Alert
          </StyledButton>
        </Box>
      )}
      <Box display="flex" flexDirection={'column'} gap="24px">
        <StatisticContainer
          globalStatistics={{ totalAlerts, totalAlertsToday: totalTodayAlerts, avgResponse }}
        />
        <Box display={'flex'} flexDirection={'column'} gap="24px">
          {userAlerts?.map((alert: UserAlert.AsObject) => {
            return (
              <DisplayAlertV1
                key={alert.id}
                alert={alert}
                userDestinations={userDestinations}
                updateAlertStatus={updateAlertStatus}
                setValue={openModal}
              />
            )
          })}
          <BasicModal
            open={value}
            handleClose={closeModal}
            actionFn={() => deleteAlert(alertId)}
            headerText={'Delete Alert'}
            messageText="Are you sure you want to delete this alert?"
            actionButtonText={'Delete'}
          />
        </Box>
      </Box>
      <AlertsViewFooter isMobile={isMobile} router={router} />
    </Box>
  )
}
