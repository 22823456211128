import { ReactComponent as MempoolsPoweredLogo } from '@/assets/MempoolsPoweredLogo.svg'
import { ReactComponent as Plus } from '@/assets/icons/Plus.svg'
import { StyledButton } from '@/common/components/StyledButton/StyledButton'
import { Box, Typography } from '@mui/material'
import Link from 'next/link'
import React from 'react'

export default function AlertsViewFooter({ isMobile, router }: any) {
  return (
    <Box
      display="flex"
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        '@media (max-width: 500px)': {
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'flex-start',
        },
      }}
    >
      {!isMobile && (
        <StyledButton onClick={() => router.push('/create')}>
          <Plus />
          New Alert
        </StyledButton>
      )}
      <Link href="https://mempools.com">
        <Box display="flex" gap="10px" alignItems={'center'}>
          <Typography color="#FFFFFF7A" fontSize="16px" width={'auto'}>
            Powered by
          </Typography>
          <MempoolsPoweredLogo />
        </Box>
      </Link>
    </Box>
  )
}
