import { UserAlert, UserDestination } from '@/proto/api_pb'
import { userService } from '@/service/UserService'
import { Box, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { getAlertData } from '../../create/utils/Utils'
import DisplayAlertFooter from './DisplayAlertFooter/DisplayAlertFooter'

type AlertStatisticType = {
  totalAlerts: number
  totalAlertsToday: number
  avgResponseTime: number
}

export const DisplayAlertV1 = ({
  alert,
  userDestinations,
  updateAlertStatus,
  setValue,
}: {
  alert: UserAlert.AsObject
  userDestinations: UserDestination.AsObject[]
  updateAlertStatus: any
  setValue: (alertId: string) => void
}) => {
  const [alertStatistic, setAlertStatistic] = useState<AlertStatisticType>()

  const alertData: any = getAlertData(alert)
  const destination: UserDestination.AsObject | undefined = userDestinations.find(
    destination => destination.id === alert.destinationIdsList[0]
  )
  const destinationData = destination?.destination?.discordWebhook
    ? { name: destination?.destination?.discordWebhook, type: 'Discord' }
    : destination?.destination?.telegram
    ? { name: destination?.destination?.telegram, type: 'Telegram' }
    : destination?.destination?.email
    ? { name: destination?.destination?.email, type: 'Email' }
    : { name: destination?.destination?.webhook, type: 'Webhook' }

  const getStatistics = useCallback(async (alertId: string) => {
    const result = await userService.getStatistics(alertId)
    if (result.status === 'FAIL') {
      console.log('error on get statistics', result)
    }

    setAlertStatistic(result?.data)
  }, [])

  useEffect(() => {
    getStatistics(alert.id)
  }, [alert.id, getStatistics])

  return (
    <Box
      key={alert?.id}
      sx={{
        backgroundColor: '#FFFFFF1F',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Box
        sx={{
          padding: '16px 24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          paddingBottom: '0px',
          '@media (max-width: 600px)': {
            padding: '16px 20px',
          },
        }}
      >
        <Box
          display="flex"
          gap="20px"
          sx={{
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              gap: '0px',
            },
          }}
        >
          <Typography color="#FFFFFF7A" fontSize="16px" width="100px" overflow={'initial'}>
            Alert Type
          </Typography>
          <Typography fontSize="16px" color="white">
            {alert.alert?.broadcastAlert ? 'Broadcast' : alertData.type}
          </Typography>
        </Box>
        {!alert.alert?.broadcastAlert && (
          <Box
            display="flex"
            gap="20px"
            sx={{
              '@media (max-width: 600px)': {
                flexDirection: 'column',
                gap: '0px',
              },
            }}
          >
            <Typography color="#FFFFFF7A" fontSize="16px" width="100px" overflow={'initial'}>
              Address
            </Typography>
            <Typography fontSize="16px" color="white">
              {alertData.setAddress}
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          gap="20px"
          sx={{
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              gap: '0px',
            },
          }}
        >
          <Typography color="#FFFFFF7A" fontSize="16px" width="100px" overflow={'initial'}>
            Destination
          </Typography>
          <Box display={'flex'} gap="12px">
            <Typography fontSize="16px" color="white" overflow={'initial'}>
              {destinationData.type}
            </Typography>
            <Typography fontSize="16px" color="#FFFFFF7A" width={'670px'}>
              {destinationData.name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <DisplayAlertFooter
        alert={alert}
        alertStatistic={alertStatistic}
        updateAlertStatus={updateAlertStatus}
        setValue={setValue}
      />
    </Box>
  )
}
