import { MempoolsSwitch } from '@/common/components/MempoolsSwitch/MempoolsSwitch'
import { ProjectChainData } from '@/data/ProjectChainData'
import { Box, Button, Typography } from '@mui/material'

export default function DisplayAlertFooter({
  updateAlertStatus,
  setValue,
  alertStatistic,
  alert,
}: any) {
  return (
    <Box
      borderTop={'1px solid #FFFFFF1F'}
      padding="16px 24px"
      display="flex"
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        '@media (max-width: 600px)': {
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'flex-start',
        },
      }}
    >
      <Box
        display="flex"
        gap="20px"
        sx={{
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            gap: '8px',
            width: '100%',
          },
        }}
      >
        <Box
          display="flex"
          gap="8px"
          sx={{
            '@media (max-width: 600px)': {
              width: '100%',
              flexDirection: 'row-reverse',
              gap: '20px',
            },
          }}
        >
          <Typography
            fontSize="16px"
            color="white"
            sx={{
              '@media (max-width: 600px)': {
                width: '50%',
              },
            }}
          >
            {alertStatistic?.totalAlerts}
          </Typography>
          <Typography
            color="#FFFFFF7A"
            fontSize="16px"
            sx={{
              '@media (max-width: 600px)': {
                width: '50%',
              },
            }}
          >
            total alerts
          </Typography>
        </Box>
        <Box
          display="flex"
          gap="8px"
          sx={{
            '@media (max-width: 600px)': {
              width: '100%',
              flexDirection: 'row-reverse',
              gap: '20px',
            },
          }}
        >
          <Typography
            fontSize="16px"
            color="white"
            sx={{
              '@media (max-width: 600px)': {
                width: '50%',
              },
            }}
          >
            {alertStatistic?.totalAlertsToday}
          </Typography>
          <Typography
            color="#FFFFFF7A"
            fontSize="16px"
            sx={{
              '@media (max-width: 600px)': {
                width: '50%',
              },
            }}
          >
            today
          </Typography>
        </Box>
        <Box
          display="flex"
          gap="8px"
          sx={{
            '@media (max-width: 600px)': {
              width: '100%',
              flexDirection: 'row-reverse',
              gap: '20px',
            },
          }}
        >
          <Typography
            fontSize="16px"
            color="white"
            sx={{
              '@media (max-width: 600px)': {
                width: '50%',
              },
            }}
          >
            {alertStatistic?.avgResponseTime
              ? Math.round(alertStatistic?.avgResponseTime * 100) / 10 + ' ms'
              : '-'}
          </Typography>
          <Typography
            color="#FFFFFF7A"
            fontSize="16px"
            sx={{
              '@media (max-width: 600px)': {
                width: '50%',
              },
            }}
          >
            avg. response time
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        gap="24px"
        alignItems={'center'}
        sx={{
          '@media (max-width: 600px)': {
            width: '100%',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
          },
        }}
      >
        <Box display="flex" gap="12px">
          <Typography fontSize="16px" color="white">
            {alert.status === 0 ? 'Active' : 'Disabled'}
          </Typography>
          <MempoolsSwitch checked={alert.status === 0} onChange={() => updateAlertStatus(alert)} />
        </Box>
        <Button
          onClick={() => setValue(alert.id)}
          sx={{
            color: 'white',
            fontSize: '16px',
            padding: 0,
            '@media (max-width: 600px)': {
              width: 'fit-content',
            },
          }}
        >
          Delete
        </Button>
      </Box>
    </Box>
  )
}