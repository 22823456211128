import { alertMessages } from '@/common/utils/alert-messages'
import { useSnackbar } from '@/contexts/SnackbarContext'
import { SnackBarContextType } from '@/contexts/SnackbarContextType'
import { UserAlert, UserDestination } from '@/proto/api_pb'
import { userService } from '@/service/UserService'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { useBoolean } from 'usehooks-ts'

export default function useAlertView() {
  const { addAlert } = useSnackbar() as SnackBarContextType

  const { value, setValue } = useBoolean(false)
  const [userAlerts, setUserAlerts] = useState<UserAlert.AsObject[]>([])
  const [userDestinations, setUserDestinations] = useState<UserDestination.AsObject[]>([])
  const [alertId, setAlertId] = useState<string>('')
  const router = useRouter()

  const getAlerts = useCallback(async () => {
    const alerts = await userService.getAlertsByUser()
    setUserAlerts(alerts.data.alertsList)
    if (alerts.status === 'SUCCESS') {
      alerts.data.alertsList.length === 0 && router.push('/create')
    }
    const destinations = await userService.getUserDestinations()
    if (destinations.status === 'SUCCESS') {
      setUserDestinations(destinations.data.userDestinationsList)
    }
  }, [router])

  const deleteAlert = async (alertId: string) => {
    const result = await userService.deleteAlert(alertId)
    if (result.status === 'FAIL') {
      console.log('error on delete alert', result)
    }
    addAlert(alertMessages.alerts.alertDeleted)
    setValue(false)
  }

  const updateAlertStatus = useCallback(
    async (alert: any) => {
      const status = alert.status === 0 ? 1 : 0
      const response = await userService.setAlertStatus(alert.id, status, alert.destinationIdsList)
      if (response.status !== 'SUCESS') {
        addAlert(alertMessages.generalError)
      }
      addAlert(alertMessages.alerts.alertUpdated)
      setUserAlerts(prevState => {
        return prevState.map(prevAlert => {
          if (prevAlert.id === alert.id) {
            return response.data.alert
          }
          return prevAlert
        })
      })
    },
    [addAlert]
  )

  const openModal = (alertId: string) => {
    setAlertId(alertId)
    setValue(true)
  }

  const closeModal = () => {
    setValue(false)
  }

  return {
    deleteAlert,
    openModal,
    value,
    alertId,
    closeModal,
    getAlerts,
    updateAlertStatus,
    userAlerts,
    userDestinations,
  }
}
