import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton, Modal, styled, Typography } from '@mui/material'
import { ProjectChainData } from '@/data/ProjectChainData'

type BasicModalProps = {
  handleClose: () => void
  open: boolean
  actionFn: () => void
  headerText: string
  messageText: string
  actionButtonText: string
}

export default function BasicModal({
  handleClose,
  open,
  actionFn,
  headerText,
  messageText,
  actionButtonText,
}: BasicModalProps) {
  const onCloseModal = () => {
    handleClose()
  }

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalStyle>
        <Box
          sx={{
            display: 'flex',
            borderBottom: '1px solid #FFFFFF3D',
            padding: '24px',
          }}
        >
          <Typography
            color="white"
            textAlign={'left'}
            sx={{
              width: '100%',
              fontSize: '22px',
              fontWeight: 400,
            }}
          >
            {headerText}
          </Typography>
          <IconButton
            onClick={onCloseModal}
            sx={{
              border: '1px solid #FFFFFF3D',
              borderRadius: '8px',
              padding: '6px',
            }}
          >
            <CloseIcon
              sx={{
                color: 'white',
                fontSize: '20px',
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: '24px',
          }}
        >
          <Typography
            sx={{
              color: 'white',
              marginBottom: '10px',
              fontSize: '16px',
            }}
          >
            {messageText}
          </Typography>
          <Box display={'flex'} gap={'12px'} mt={'20px'}>
            <StyledButton onClick={actionFn}>{actionButtonText}</StyledButton>
            <CancelStyledButton onClick={onCloseModal}>Cancel</CancelStyledButton>
          </Box>
        </Box>
      </ModalStyle>
    </Modal>
  )
}
const ModalStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 'auto',
  width: 500,
  backgroundColor: ProjectChainData.MODAL_BG_COLOR,
  padding: '0',
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    width: '350px',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  backgroundColor: ProjectChainData.BUTTON_COLOR_WL,
  borderRadius: '8px',
  color: 'white',
  padding: '8px 12px',
  gap: '10px',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: ProjectChainData.BUTTON_HOVER_COLOR_WL,
  },
}))

const CancelStyledButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  backgroundColor: 'transparent',
  border: '1px solid var(--white-24, rgba(255, 255, 255, 0.24))',
  borderRadius: '8px',
  color: 'white',
  padding: '8px 12px',
  gap: '10px',
  fontSize: '16px',
}))
