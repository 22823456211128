import { AlertTypeEnum, EthAlertTypeEnum, EvmAlertTypeEnum } from '@/common/enums/AlertTypeEnum'
import { Alert, UserAlert } from '@/proto/api_pb'

export function checkAlertType(alert: Alert.AsObject) {
  if (Boolean(alert.cosmosAlert)) {
    if (Boolean(alert.cosmosAlert?.alertCosmosMonitorFunds)) {
      return AlertTypeEnum.MONITOR_FUNDS
    } else if (Boolean(alert.cosmosAlert?.alertCosmosSendFunds)) {
      return AlertTypeEnum.SEND_FUNDS
    } else if (Boolean(alert.cosmosAlert?.alertCosmosSmartContractEvents)) {
      return AlertTypeEnum.SMART_CONTRACT_EVENTS
    } else if (Boolean(alert.cosmosAlert?.alertCosmosTxOutcome)) {
      return AlertTypeEnum.TX_OUTCOME
    }
  }
  if (Boolean(alert.cosmosEvmAlert)) {
    if (Boolean(alert.cosmosEvmAlert?.alertEthMonitorFunds)) {
      return EvmAlertTypeEnum.EVM_MONITOR_FUNDS
    } else if (Boolean(alert.cosmosEvmAlert?.alertEthSmartContractEvents)) {
      return EvmAlertTypeEnum.EVM_SMART_CONTRACT_EVENTS
    } else if (Boolean(alert.cosmosEvmAlert?.alertEthTxOutcome)) {
      return EvmAlertTypeEnum.EVM_TX_OUTCOME
    } else if (Boolean(alert.cosmosEvmAlert?.alertCosmosMonitorFunds)) {
      return EvmAlertTypeEnum.COSMOS_MONITOR_FUNDS
    } else if (Boolean(alert.cosmosEvmAlert?.alertCosmosTxOutcome)) {
      return EvmAlertTypeEnum.COSMOS_TX_OUTCOME
    }
  }
  if (Boolean(alert.ethAlert)) {
    if (Boolean(alert.ethAlert?.alertEthMonitorFunds)) {
      return EthAlertTypeEnum.ETH_MONITOR_FUNDS
    } else if (Boolean(alert.ethAlert?.alertEthSmartContractEvents)) {
      return EthAlertTypeEnum.ETH_SMART_CONTRACT_EVENTS
    } else if (Boolean(alert.ethAlert?.alertEthTxOutcome)) {
      return EthAlertTypeEnum.ETH_TX_OUTCOME
    }
  }
}

export function getAlertData(alert: UserAlert.AsObject) {
  let alertData = {}
  if (alert.alert?.cosmosAlert) {
    alert.alert?.cosmosAlert?.alertCosmosSendFunds
      ? (alertData = {
          type: 'Monitor Funds',
          setAddress: alert.alert.cosmosAlert.alertCosmosSendFunds?.to,
          setFrom: alert.alert.cosmosAlert.alertCosmosSendFunds?.from,
        })
      : alert.alert?.cosmosAlert?.alertCosmosMonitorFunds
      ? (alertData = {
          type: 'Monitor Wallet',
          setAddress: alert.alert.cosmosAlert.alertCosmosMonitorFunds?.address,
        })
      : alert.alert?.cosmosAlert?.alertCosmosSmartContractEvents
      ? (alertData = {
          type: 'Monitor Smart Contract',
          setAddress: alert.alert.cosmosAlert.alertCosmosSmartContractEvents?.address,
        })
      : alert.alert?.cosmosAlert?.alertCosmosTxOutcome
      ? (alertData = {
          type: 'Monitor Transaction',
          setAddress: alert.alert.cosmosAlert.alertCosmosTxOutcome?.signer,
          outcome: alert.alert.cosmosAlert.alertCosmosTxOutcome?.outcome,
        })
      : {
          setAddress: '',
        }
  } else if (alert.alert?.ethAlert) {
    alert.alert.ethAlert.alertEthMonitorFunds
      ? (alertData = {
          type: 'Monitor Wallet',
          setAddress: alert.alert.ethAlert.alertEthMonitorFunds.address,
        })
      : alert.alert.ethAlert.alertEthSmartContractEvents
      ? (alertData = {
          type: 'Monitor Smart Contract',
          setAddress: alert.alert.ethAlert.alertEthSmartContractEvents.contractAddr,
        })
      : {
          setAddress: '',
        }
  }
  return alertData
}
